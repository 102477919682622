import React from 'react'
import {connect} from 'react-redux'

import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

import {setAndRememberDetailMode} from '../actions/thunks'
import {DetailMode, RootState} from '../store/types'

interface Props {
  mode: DetailMode
  setMode: (mode: DetailMode) => void
}

const modes: [DetailMode, string][] = [
  ['modal', 'Modal'],
  ['card', 'Card'],
]

const mapStateToProps = (state: RootState) => ({
  mode: state.ui.detailMode,
})

const DetailModeToggle: React.FC<Props> = ({mode, setMode}) => {
  return (
    <ButtonGroup>
      {modes.map(([buttonMode, description], i) => (
        <Button
          key={i}
          active={buttonMode === mode}
          onClick={() => setMode(buttonMode)}
          variant="outline-primary"
        >
          {description}
          <span className="d-none d-sm-inline"> view</span>
        </Button>
      ))}
    </ButtonGroup>
  )
}

export default connect(mapStateToProps, {setMode: setAndRememberDetailMode})(
  DetailModeToggle,
)
