import React, {useState, Ref, forwardRef} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import Modal, {RenderModalBackdropProps} from 'react-overlays/Modal'
import Card from 'react-bootstrap/Card'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faLockOpen,
  faCircle,
  faRedoAlt,
} from '@fortawesome/free-solid-svg-icons'
import {faPatreon} from '@fortawesome/free-brands-svg-icons'

import {UserState, RootState, ACCESS_CODE_GRANTS} from '../store/types'
import {patronDescriptionForUser} from '../patreon'
import {externalLinkProps} from './externalLinks'
import {linkPatreonAccount, logoutUser} from '../actions/thunks'

import DetailModeToggle from './DetailModeToggle'
import FreeColumnToggle from './FreeColumnToggle'
import DateFormatToggle from './DateFormatToggle'
import styles from './AccountDetails.module.scss'
import ThemeToggle from './ThemeToggle'
import utilityStyles from './common.module.scss'

const DISCORD_URL = 'https://discord.gg/BNegdmM'

const _Backdrop = (
  props: RenderModalBackdropProps,
  ref: Ref<HTMLDivElement>,
) => {
  return <div className={styles.backdrop} {...props} ref={ref} />
}
const Backdrop = forwardRef(_Backdrop)

interface Props {
  show: boolean
  onHide: () => void
  user: UserState | null
  linkPatreonAccount: () => Promise<void>
  logoutUser: () => Promise<void>
}

const mapStateToProps = (state: RootState) => ({
  user: state.auth.user,
})

const AccountDetails: React.FC<Props> = ({user, show, onHide, ...props}) => {
  const [loadingPatreon, setLoadingPatreon] = useState(false)
  const renderBackdrop = (props: RenderModalBackdropProps) => (
    <Backdrop {...props} />
  )

  return (
    user && (
      <Modal
        show={show}
        onHide={onHide}
        renderBackdrop={renderBackdrop}
        className={styles.modal}
      >
        <div className={styles.card}>
          <div className={styles.header}>
            <div className={styles.email}>{user.email}</div>
            {user.accessCodeGrants?.map(grant => {
              let name: string
              switch (grant) {
                case ACCESS_CODE_GRANTS.TOTAL:
                  name = 'Total Access'
                  break
                case ACCESS_CODE_GRANTS.BOOK_1:
                  name = 'Book 1'
                  break
                case ACCESS_CODE_GRANTS.BOOK_2:
                  name = 'Book 2'
                  break
                default:
                  return
              }

              return (
                <Card.Subtitle key={grant}>
                  <span className="fa-layers fa-fw">
                    <FontAwesomeIcon fixedWidth icon={faLockOpen} size="xs" />
                  </span>{' '}
                  {name}: Unlocked
                </Card.Subtitle>
              )
            })}
            {user.patreonUserId && (
              <Card.Subtitle>
                <FontAwesomeIcon
                  fixedWidth
                  icon={faPatreon}
                  mask={faCircle}
                  transform="shrink-6"
                />{' '}
                {patronDescriptionForUser(user)}
              </Card.Subtitle>
            )}
          </div>
          <div className={styles.items}>
            {loadingPatreon ? (
              <div className={styles.menuItem}>
                <span>Loading Patreon...</span>
              </div>
            ) : (
              <a
                href="/"
                className={styles.menuItem}
                onClick={async e => {
                  e.preventDefault()
                  setLoadingPatreon(true)
                  await props.linkPatreonAccount()
                  window.blur()
                }}
              >
                <span>
                  {user.patreonUserId
                    ? 'Refresh Patreon Connection'
                    : 'Link Patreon Account'}
                </span>
                <FontAwesomeIcon
                  icon={user.patreonUserId ? faRedoAlt : faChevronRight}
                  fixedWidth
                />
              </a>
            )}
            <Link to="/apply-code" className={styles.menuItem}>
              <span>Apply Access Code</span>
              <FontAwesomeIcon icon={faChevronRight} fixedWidth />
            </Link>
            <a
              className={styles.menuItem}
              href={DISCORD_URL}
              {...externalLinkProps}
            >
              <span>Get Help on Discord</span>
              <img
                src="/images/icon-arrow-external.png"
                className={utilityStyles.lightOnly}
                style={{width: 16, marginLeft: 5, marginBottom: 5}}
                alt=""
              />
              <img
                src="/images/icon-arrow-external-dark.png"
                className={utilityStyles.darkOnly}
                style={{width: 16, marginLeft: 5, marginBottom: 5}}
                alt=""
              />
            </a>
            <a
              className={styles.menuItem}
              href="mailto:thegriffonssaddlebag@gmail.com"
            >
              <span>Email Support</span>
              <img
                src="/images/icon-arrow-external.png"
                className={utilityStyles.lightOnly}
                style={{width: 16, marginLeft: 5, marginBottom: 5}}
                alt=""
              />
              <img
                src="/images/icon-arrow-external-dark.png"
                className={utilityStyles.darkOnly}
                style={{width: 16, marginLeft: 5, marginBottom: 5}}
                alt=""
              />
            </a>
            <a
              href="/"
              className={styles.menuItem}
              onClick={async e => {
                e.preventDefault()
                await props.logoutUser()
              }}
            >
              <span>Log Out</span>
              <FontAwesomeIcon icon={faChevronRight} fixedWidth />
            </a>
          </div>
          <div className={styles.toggleContainer}>
            <div className={styles.toggleLabel}>Display theme</div>
            <ThemeToggle />
          </div>
          <div className={styles.toggleContainer}>
            <div className={styles.toggleLabel}>Item display mode</div>
            <DetailModeToggle />
          </div>
          <div className={styles.toggleContainer}>
            <div className={styles.toggleLabel}>Free column</div>
            <FreeColumnToggle />
          </div>
          <div className={styles.toggleContainer}>
            <div className={styles.toggleLabel}>Date format</div>
            <DateFormatToggle />
          </div>
        </div>
      </Modal>
    )
  )
}

export default connect(mapStateToProps, {linkPatreonAccount, logoutUser})(
  AccountDetails,
)
