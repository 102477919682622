import {User as FirebaseUser} from 'firebase/auth'
import {Timestamp as FirestoreTimestamp} from 'firebase/firestore'

import {ActionType} from 'typesafe-actions'

import {actions} from '../actions'

export type RootAction = ActionType<typeof actions>

declare module 'typesafe-actions' {
  interface Types {
    RootAction: RootAction
  }
}

export interface RootState {
  totalItemCount: number
  contents: null | GriffonDatabaseItem[]
  selectedItemId: string | null
  ui: {
    listMode: 'list' | 'grid'
    detailMode: DetailMode
    dateFormat: DateFormat
    showPaid: boolean
    savingItem: boolean
    navExpanded: boolean
    navExtraVerticalSpace: number
    accountShowing: boolean
    freeColumnVisible: boolean
  }
  auth: AuthState
  myBundles: {[id: string]: ItemBundle}
  selectedBundleId: string | null
  listViews: {
    home: ListState
    stash: ListState
    stashAdd: ListState
  }
}

export type DetailMode = 'modal' | 'card'
export type DateFormat = 'long' | 'short'
export type DateColumnMode = 'date' | 'card' | 'book'

export type Rarity =
  | 'common'
  | 'uncommon'
  | 'rare'
  | 'very rare'
  | 'legendary'
  | 'artifact'

export type ItemType =
  | 'Weapon'
  | 'Armor'
  | 'Wondrous item'
  | 'Ring'
  | 'Rod'
  | 'Wand'
  | 'Staff'
  | 'Potion'
  | 'Scroll'

export interface GriffonDatabaseItem {
  id: string
  name: string
  free: boolean
  attunement: string | boolean
  description: string
  imageIds?: string[]
  table?: string
  flavour?: string
  itemType: ItemType
  itemSubtype?: string
  rarity: string
  rarities: Rarity[]
  tags?: Array<
    | 'bracers'
    | 'clothing'
    | 'outerwear'
    | 'footwear'
    | 'handwear'
    | 'accessory'
    | 'cloak'
    | 'consumable'
    | 'headwear'
    | 'quiver'
    | 'instrument'
    | 'container'
  >
  patreonArtUrl?: string
  patreonCardUrl?: string
  significance?: 'major' | 'minor' | ''
  /**
   * zero-indexed month (0 == January, 1 == February, ..., 11 == December)
   */
  dateMonth?: number
  dateYear?: number
  dateDay?: number
}

export type UnsavedGriffonItem = Omit<GriffonDatabaseItem, 'id'> & {
  id?: GriffonDatabaseItem['id']
}

export type GriffonItem = GriffonDatabaseItem & {
  cardVolume: 1 | 2 | 3 | 4 | 5 | 6 | 7 | null
  bookVolume: 1 | 2 | 3 | null
}

export interface ISearchTag<T extends string, V = string> {
  value: V
  type: T
}

export type RaritySearchTag = ISearchTag<
  'rarity',
  Rarity | '__multiple_rarities__'
>

export type SearchTag =
  | ISearchTag<'itemType'>
  | ISearchTag<'itemSubtype'>
  | ISearchTag<'name'>
  | RaritySearchTag
  | ISearchTag<'significance'>
  | ISearchTag<'requiresAttunement', boolean>
  | ISearchTag<'isFree', boolean>
  | ISearchTag<'text:nameAndMeta'>
  | ISearchTag<'text:description'>
  | ISearchTag<'text:flavour'>
  | ISearchTag<'cardVolume', number>
  | ISearchTag<'bookVolume', number>

export interface ItemFilterState {
  attunement?: boolean
  attunementType: string[]
  itemType: string[]
  itemSubtype: string[]
  tagSearch: readonly SearchTag[]
}

export interface SaddlebagFilterOptions {
  attunementType: string[]
  itemType: string[]
  itemSubtype: {[itemType: string]: string[]}
}

export type SimpleFirebaseUser = Pick<FirebaseUser, 'email' | 'uid'>

export interface UserState extends SimpleFirebaseUser {
  uid: string
  email: string | null
  isAdmin: boolean
  patreonEntitledAmountCents: number
  patreonUserId: string | null
  eligibilityStatusUpdatedAt: Date | null
  accessCodeGrants: string[] | undefined
  patreonEliteHeroTier: boolean | undefined
}

interface AuthState {
  isLoggingIn: boolean
  isLoggingOut: boolean
  isVerifying: boolean
  loginError: boolean
  logoutError: boolean
  isAuthenticated: boolean
  user: UserState | null
}

export type ListIdentifier = 'home' | 'stash' | 'stashAdd'

export interface ListState {
  identifier: ListIdentifier
  filters: ItemFilterState
  sortColumn: SortColumn
  sortDirection: SortDirection
  visibleSecondaryColumn: Exclude<SortColumn, 'name'>
  dateColumnMode: DateColumnMode
}

export const SORT_ASC = 1
export const SORT_DESC = -1

export type SortDirection = typeof SORT_ASC | typeof SORT_DESC

export type SortColumn =
  | 'rarity'
  | 'type'
  | 'free'
  | 'significance'
  | 'attunement'
  | 'name'
  | 'date'

export interface ItemBundle {
  id: string
  type?: 'tag' | 'fave'
  name: string
  items: string[]
}

export type MaybeUnsaved<T extends {id: string}> =
  | T
  | (Omit<T, 'id'> & {id?: string})

export interface WithId {
  id: string
}

export type AccessCodeType = 'total_access' | 'book_1' | 'book_2'

export const ACCESS_CODE_GRANTS: Record<
  'TOTAL' | 'BOOK_1' | 'BOOK_2',
  AccessCodeType
> = {
  TOTAL: 'total_access',
  BOOK_1: 'book_1',
  BOOK_2: 'book_2',
}

interface AccessCodeMeta {
  type: AccessCodeType
  note: string
  generatedAt: FirestoreTimestamp
  generatedBy: string
}

export interface AccessCodeBatch extends AccessCodeMeta {
  size: number
}

export interface AccessCode extends AccessCodeMeta {
  batchId: string
  claimedAt: FirestoreTimestamp | null
  claimedBy: string | null
}
