import React, {useState, useRef, MouseEvent} from 'react'
import classnames from 'classnames'

import Div100vh from 'react-div-100vh'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPatreon} from '@fortawesome/free-brands-svg-icons'
import {faEdit} from '@fortawesome/free-regular-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Badge from 'react-bootstrap/Badge'
import {LinkContainer} from 'react-router-bootstrap'

import * as types from '../store/types'
import {itemMetaString, formatSubrarity, rarityLetterForItem} from '../utils'

import ItemDescription from './ItemDescription'
import ImageDisplay from './ImageDisplay'
import Square from './Square'
import AddToStashTooltip from './stashes/AddToStashTooltip'
import ItemDateSnippet from './ItemDateSnippet'
import DateModeLabel from './DateModeLabel'
import SvgIcon from './SvgIcon'

import styles from './ItemDetailModal.module.scss'

interface ItemProps {
  item: types.GriffonItem
  user: types.UserState | null
  hasPatreonArtAccess: boolean
  hasPatreonCardAccess: boolean
  exit: () => void
}

export const PatreonLink: React.FC<{
  url: string
  hasAccess: boolean
  type: 'cards' | 'art'
  linkStyle?: 'cardWash'
  tooltipPlacement?: 'bottom'
}> = props => {
  const [mobileTapped, setMobileTapped] = useState(false)
  const isSmallScreen = () => window.innerWidth < 992

  const onClick = (e: MouseEvent) => {
    if (props.hasAccess) return

    if (isSmallScreen() && !mobileTapped) {
      setMobileTapped(true)
      e.preventDefault()
    }
  }
  const link = (
    <a
      href={props.url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
      className={
        props.linkStyle === 'cardWash' ? 'btn btn-outline-primary tinted' : ''
      }
    >
      {props.linkStyle === 'cardWash' ? (
        props.type === 'cards' ? (
          <>
            Card<span className="d-none d-sm-inline"> files</span>
          </>
        ) : (
          <>
            Art<span className="d-none d-sm-inline"> files</span>
          </>
        )
      ) : props.type === 'cards' ? (
        'Cards'
      ) : (
        'Art'
      )}
      {/* Below doesn't look good without white arrow image */}
      {/* {props.linkStyle === 'cardWash' && (
        <img
          src="/images/icon-arrow-external.png"
          alt=""
          style={{width: 16, marginLeft: 5, marginBottom: 5}}
        />
      )} */}
    </a>
  )

  return (
    <>
      {props.hasAccess ? (
        link
      ) : (
        <OverlayTrigger
          overlay={
            <Tooltip id={`no-access-${props.type}`}>
              {props.type === 'cards' ? '$5+ Patrons' : '$1+ Patrons'}
            </Tooltip>
          }
          trigger={isSmallScreen() ? 'focus' : ['hover', 'focus']}
          placement={props.tooltipPlacement}
        >
          {link}
        </OverlayTrigger>
      )}
    </>
  )
}

const FullScreenModalItemContainer: React.FC<{
  exit?: () => void
  overflowY: 'auto' | 'scroll'
}> = ({children, exit, overflowY}) => (
  <Div100vh
    className={styles.fullScreenModalItemContainer}
    onClick={e => {
      if (exit && e.target === e.currentTarget) {
        exit()
      }
    }}
    style={{
      position: 'absolute',
      width: '100%',
      overflowY,
      height: '100rvh',
    }}
  >
    {children}
  </Div100vh>
)

export const ItemDetailModalGalleryItem: React.FC<ItemProps> = props => {
  const stashMenuTarget = useRef(null)
  const modalDialogRef = useRef<HTMLDivElement>(null)
  const [showingStashList, toggleStashList] = useState(false)

  const {item, hasPatreonArtAccess, hasPatreonCardAccess} = props
  return (
    <div>
      {stashMenuTarget?.current && modalDialogRef?.current && (
        <AddToStashTooltip
          show={showingStashList}
          onHide={() => toggleStashList(false)}
          target={stashMenuTarget?.current}
          container={modalDialogRef.current}
        />
      )}
      <FullScreenModalItemContainer exit={props.exit} overflowY="auto">
        <div className="modal-dialog saddlebag-item-modal" ref={modalDialogRef}>
          <div className="modal-content">
            <Modal.Header closeButton>
              <strong className="text-center" style={{flexGrow: 1}}>
                {item.name}
              </strong>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col sm={5}>
                  {item.imageIds && (
                    <div className="img-fluid">
                      <Square>
                        <ImageDisplay
                          link
                          key={item.id}
                          imageId={item.imageIds?.[0]}
                          size="medium"
                          thumbnail={false}
                        />
                      </Square>
                    </div>
                  )}
                  <div className="text-center">
                    <p>
                      <strong>Subrarity</strong>
                      <br />
                      {formatSubrarity(item) || '-'}
                    </p>
                    <p>
                      <strong style={{marginLeft: '-26px'}}>
                        <DateModeLabel />
                      </strong>
                      <br />
                      <ItemDateSnippet item={item} none="N/A" />
                    </p>
                    {/* DRY this */
                    props.user && (
                      <p>
                        <Button
                          variant="primary"
                          ref={stashMenuTarget}
                          onClick={() => toggleStashList(true)}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                            }}
                          >
                            Stash It &nbsp;
                            <SvgIcon iconName="stashes" size={16} />
                          </div>
                        </Button>
                      </p>
                    )}
                    {props.user?.isAdmin && (
                      <p>
                        <LinkContainer to={`/items/${item.id}/edit`}>
                          <Button>
                            Edit &nbsp;
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                        </LinkContainer>
                      </p>
                    )}
                    {(item.patreonArtUrl || item.patreonCardUrl) && (
                      <p>
                        <strong>
                          <FontAwesomeIcon icon={faPatreon} /> &nbsp;Patron
                          Files
                        </strong>
                        <br />
                        {item.patreonArtUrl && (
                          <PatreonLink
                            url={item.patreonArtUrl}
                            hasAccess={item.free || hasPatreonArtAccess}
                            type="art"
                          />
                        )}
                        {item.patreonArtUrl && item.patreonCardUrl && (
                          <>&nbsp; | &nbsp;</>
                        )}
                        {item.patreonCardUrl && (
                          <PatreonLink
                            url={item.patreonCardUrl}
                            hasAccess={item.free || hasPatreonCardAccess}
                            type="cards"
                          />
                        )}
                      </p>
                    )}
                    {item.free && (
                      <Badge
                        pill
                        variant="success"
                        style={{marginBottom: '1rem '}}
                      >
                        Free
                      </Badge>
                    )}
                  </div>
                </Col>
                <Col sm={7} className={styles.detailcolumn}>
                  <br />

                  {/* {hasFaveBundle && (
                    <Button
                      size="sm"
                      variant={isFave ? 'danger' : 'outline-primary'}
                      onClick={() => setItemFave(item, !isFave)}
                    >
                      <FontAwesomeIcon
                        icon={isFave ? faHeart : faHeartOutline}
                      />
                    </Button>
                  )} */}
                  {/* TODO: Tag field */}
                  {/* {bundles.map(b => b.name).join(', ')} */}

                  <h5 className="font-weight-bold">{item.name}</h5>
                  <p>
                    <em>{itemMetaString(item)}</em>
                  </p>
                  <ItemDescription markup={item.description} />

                  {item.table && <pre>{item.table}</pre>}
                  {item.flavour && (
                    <ItemDescription flavour markup={item.flavour} />
                  )}
                </Col>
              </Row>
            </Modal.Body>
          </div>
        </div>
      </FullScreenModalItemContainer>
    </div>
  )
}

const ItemDetailCardRibbonIcon: React.FC<{
  item: types.GriffonItem
  icon: 'rarity' | 'type'
}> = props => {
  const {item} = props
  let imageSrc = ''
  let imageAlt = ''

  if (props.icon === 'rarity') {
    const letter = rarityLetterForItem(item)
    const suffix = item.attunement
      ? '-star'
      : item.rarities.length > 1
      ? '-plus'
      : ''
    imageSrc =
      '/images/card-item-rarities/_centered/' + letter + suffix + '.png'
    imageAlt = itemMetaString(item)
  } else {
    imageSrc =
      '/images/card-item-type-icons/_centered/' + item.itemType + '.png'
    imageAlt = item.itemType
  }

  return <img className={styles.cardRibbonIcon} src={imageSrc} alt={imageAlt} />
}

export const ItemDetailCardGalleryItem: React.FC<ItemProps> = props => {
  const {item, exit, user, hasPatreonArtAccess, hasPatreonCardAccess} = props
  return (
    <div>
      <FullScreenModalItemContainer exit={props.exit} overflowY="scroll">
        <div className="modal-dialog saddlebag-item-card">
          <ItemDetailCardHeader
            exit={() => null}
            item={item}
            user={user}
            hasPatreonArtAccess={hasPatreonArtAccess}
            hasPatreonCardAccess={hasPatreonCardAccess}
          />
          <div className={styles.card}>
            <div className={styles.cardContent}>
              {item.imageIds && (
                <div
                  className="img-fluid"
                  style={
                    // aligned left and no overlap with ribbon
                    {width: '85%', margin: '0'}
                  }
                >
                  <Square>
                    <ImageDisplay
                      link
                      key={item.id}
                      imageId={item.imageIds?.[0]}
                      size="medium"
                      thumbnail={false}
                    />
                  </Square>
                </div>
              )}
              <div
                className={styles.cardCloseButton}
                onClick={e => {
                  if (exit && e.target === e.currentTarget) {
                    exit()
                  }
                }}
              >
                &times;
              </div>
              <div className={styles.cardCornerRibbon}>
                <ItemDetailCardRibbonIcon item={item} icon="type" />
                <ItemDetailCardRibbonIcon item={item} icon="rarity" />
              </div>
              <div className={styles.cardTextContent}>
                <div className={styles.cardItemName}>{item.name}</div>
                <div className={styles.cardMetaDescription}>
                  {itemMetaString(item)}
                </div>
                <ItemDescription forCard markup={item.description} />
              </div>
            </div>
          </div>
          <div className={styles.subCard}>
            <div className={classnames(styles.subCardMeta)}>
              <div>
                <strong>Subrarity</strong>
                <br />
                {formatSubrarity(item) || '-'}
              </div>
              <div>{/* spacer */}</div>
              <div>
                <strong style={{marginLeft: '-26px'}}>
                  <DateModeLabel />
                </strong>
                <br />
                <ItemDateSnippet item={item} none="N/A" />
              </div>
            </div>
            {item.flavour && (
              <div style={{paddingTop: 35, paddingBottom: 35}}>
                <ItemDescription flavour markup={item.flavour} />
              </div>
            )}
          </div>
        </div>
      </FullScreenModalItemContainer>
    </div>
  )
}

export const ItemDetailCardHeader: React.FC<ItemProps> = props => {
  const stashMenuTarget = useRef(null)
  const tooltipContainerRef = useRef<HTMLDivElement>(null)
  const [showingStashList, toggleStashList] = useState(false)

  const {item, hasPatreonArtAccess, hasPatreonCardAccess} = props

  return (
    <>
      <div className={styles.cardHeader}>
        <div style={{margin: 0}} ref={tooltipContainerRef} />
        {stashMenuTarget?.current && tooltipContainerRef?.current && (
          <AddToStashTooltip
            show={showingStashList}
            onHide={() => toggleStashList(false)}
            target={stashMenuTarget?.current}
            container={tooltipContainerRef.current}
            placement="bottom"
          />
        )}
        {item.patreonArtUrl && (
          <PatreonLink
            linkStyle="cardWash"
            url={item.patreonArtUrl}
            hasAccess={item.free || hasPatreonArtAccess}
            type="art"
            tooltipPlacement="bottom"
          />
        )}
        {item.patreonCardUrl && (
          <PatreonLink
            linkStyle="cardWash"
            url={item.patreonCardUrl}
            hasAccess={item.free || hasPatreonCardAccess}
            type="cards"
            tooltipPlacement="bottom"
          />
        )}
        {/* DRY this */
        props.user && (
          <Button
            variant="primary"
            ref={stashMenuTarget}
            onClick={() => toggleStashList(true)}
          >
            <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
              +
              <SvgIcon iconName="stashes" size={16} />
            </div>
          </Button>
        )}
        {props.user?.isAdmin && (
          <LinkContainer to={`/items/${item.id}/edit`}>
            <Button>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </LinkContainer>
        )}
      </div>
    </>
  )
}
