import React, {useEffect, useState} from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {Route, Switch} from 'react-router-dom'

import {getAuth, onAuthStateChanged} from 'firebase/auth'
import {actions} from './actions'
import {
  getUserFromDB,
  loadMyBundles,
  loadVisibleItems,
  logInWithEmailLink,
} from './actions/thunks'
import AccountDetails from './components/AccountDetails'
import BundleList from './components/BundleList'
import BundleView from './components/BundleView'
import ClaimAccessCode from './components/ClaimAccessCode'
import Home from './components/Home'
import ItemDetailModal from './components/ItemDetailModal'
import Login from './components/Login'
import ProtectedRoute from './components/ProtectedRoute'
import {BookExport} from './components/admin/BookExport'
import FoundryBundle from './components/admin/FoundryBundle'
import GenerateCodes from './components/admin/GenerateCodes'
import ItemForm from './components/admin/ItemForm'
import ListCodeBatches from './components/admin/ListCodeBatches'
import ListCodes from './components/admin/ListCodes'
import StashView from './components/stashes/StashView'
import {myFirebase} from './firebase/firebase'
import {StoreDispatch} from './store'
import {RootState} from './store/types'

const auth = getAuth(myFirebase)

const App: React.FC = () => {
  const {isAdmin, isAuthenticated, isVerifying} = useSelector(
    (state: RootState) => ({
      isAdmin: Boolean(state.auth.user?.isAdmin),
      isAuthenticated: state.auth.isAuthenticated,
      isVerifying: state.auth.isVerifying,
    }),
  )
  const dispatch = useDispatch<StoreDispatch>()
  const [hasCheckedEmailLink, setCheckedEmailLink] = useState(false)

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, async user => {
      console.log('onAuthStateChanged', user)
      if (user === null) {
        dispatch(actions.verifySucceeded())
        dispatch(actions.logoutSucceeded())
        return
      }

      dispatch(actions.setSaddlebagContents([]))

      let dbUser
      try {
        dbUser = await getUserFromDB(user)
      } catch (e) {
        console.error(e)
        dispatch(actions.verifySucceeded())
        return
      }

      dispatch(actions.loginSucceeded(dbUser))
      dispatch(actions.verifySucceeded())
    })

    return unsub
  }, [dispatch])

  const user = useSelector((state: RootState) => state.auth.user)

  useEffect(() => {
    dispatch(logInWithEmailLink()).then(() => setCheckedEmailLink(true))
  }, [dispatch])

  useEffect(() => {
    if (!hasCheckedEmailLink) {
      console.log('Auth state changed but email link not checked yet')
      return
    }
    if (isVerifying) {
      console.log('Auth state changed but still verifying')
      return
    }

    console.log(`User changed ${user?.uid}, loading visible items`)
    dispatch(loadVisibleItems())
    dispatch(loadMyBundles())
  }, [dispatch, user, hasCheckedEmailLink, isVerifying])

  return (
    <>
      <Switch>
        <Route path="/login" component={Login} />
        <ProtectedRoute
          adminOnly
          path="/foundry"
          component={FoundryBundle}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          isAdmin={isAdmin}
        />
        <ProtectedRoute
          adminOnly
          path="/new"
          component={ItemForm}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          isAdmin={isAdmin}
        />
        <ProtectedRoute
          adminOnly
          path="/items/:id/edit"
          component={ItemForm}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          isAdmin={isAdmin}
        />
        <ProtectedRoute
          path="/stashes/new"
          component={BundleView}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          path="/stashes/:stashId"
          component={StashView}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          path="/stashes"
          component={BundleList}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          path="/apply-code"
          component={ClaimAccessCode}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          path="/account"
          component={AccountDetails}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          adminOnly
          path="/codes/generate"
          component={GenerateCodes}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          isAdmin={isAdmin}
        />
        <ProtectedRoute
          adminOnly
          path="/codes/:batchId"
          component={ListCodes}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          isAdmin={isAdmin}
        />
        <ProtectedRoute
          adminOnly
          path="/codes"
          component={ListCodeBatches}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          isAdmin={isAdmin}
        />
        <ProtectedRoute
          adminOnly
          path="/export/book/:number"
          component={BookExport}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
          isAdmin={isAdmin}
        />
        <Route path="/" component={Home} />
      </Switch>

      <Route
        exact
        path={['/items/:id', '/stashes/:bundleId/items/:id']}
        component={ItemDetailModal}
      />
    </>
  )
}

export default App
