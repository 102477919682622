import React, {useEffect} from 'react'

import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSun, faMoon, IconDefinition} from '@fortawesome/free-solid-svg-icons'
import {useLocalStorage} from 'react-use'

type Theme = 'dark' | 'light' | 'auto'

const modes: [Theme, string, IconDefinition | null][] = [
  ['light', 'Light', faSun],
  ['dark', 'Dark', faMoon],
  ['auto', 'Auto', null],
]

const ThemeToggle: React.FC = () => {
  const [theme, setTheme] = useLocalStorage('ledgerplus-theme', 'auto', {
    raw: true,
  })
  useEffect(() => {
    if (theme) document.documentElement.setAttribute('data-theme', theme)
  }, [theme])

  return (
    <ButtonGroup>
      {modes.map(([themeOption, description, icon], i) => (
        <Button
          key={i}
          active={themeOption === theme}
          onClick={() => setTheme(themeOption)}
          variant="outline-primary"
        >
          {icon && (
            <>
              <FontAwesomeIcon icon={icon} />{' '}
            </>
          )}
          {description}
        </Button>
      ))}
    </ButtonGroup>
  )
}

export default ThemeToggle
