import {createSelector} from 'reselect'
import {RootState, UserState, ACCESS_CODE_GRANTS} from '../store/types'
import {PLEDGE_CENTS} from '../patreon'

export const getCurrentUser = (state: RootState): UserState | null =>
  state.auth.user

export const currentUserIsAdmin = createSelector(
  [getCurrentUser],
  currentUser => Boolean(currentUser && currentUser.isAdmin),
)

export const currentUserHasPatreonAccess = createSelector(
  [getCurrentUser],
  currentUser => {
    if (!currentUser) return false

    if (currentUser.patreonEliteHeroTier !== undefined)
      return currentUser.patreonEliteHeroTier

    return currentUser.patreonEntitledAmountCents >= PLEDGE_CENTS
  },
)

export const currentUserHasTotalAccess = createSelector(
  [getCurrentUser],
  currentUser =>
    Boolean(
      currentUser !== null &&
        currentUser.accessCodeGrants?.includes(ACCESS_CODE_GRANTS.TOTAL),
    ),
)

export const currentUserHasBook1Access = createSelector(
  [getCurrentUser],
  currentUser =>
    Boolean(
      currentUser !== null &&
        currentUser.accessCodeGrants?.includes(ACCESS_CODE_GRANTS.BOOK_1),
    ),
)

export const currentUserHasBook2Access = createSelector(
  [getCurrentUser],
  currentUser =>
    Boolean(
      currentUser !== null &&
        currentUser.accessCodeGrants?.includes(ACCESS_CODE_GRANTS.BOOK_2),
    ),
)

export const currentUserCanSeeAllItems = createSelector(
  [currentUserIsAdmin, currentUserHasPatreonAccess, currentUserHasTotalAccess],
  (isAdmin, hasPatreonAccess, hasTotalAccess) =>
    isAdmin || hasPatreonAccess || hasTotalAccess,
)
