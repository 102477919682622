import {UserState} from './store/types'

export const PLEDGE_CENTS = 900

const patronTitleForPledgeCents = (pledgeCents: number): string | null => {
  const levels: [number, string][] = [
    [1000, 'Patron Saint'],
    [900, 'Ascended Hero'],
    [700, 'Elite Hero'],
    [500, 'Renowned Hero'],
    [300, 'Wandering Hero'],
    [100, 'Local Hero'],
  ]

  for (const [levelCents, levelTitle] of levels) {
    if (pledgeCents >= levelCents) return levelTitle
  }

  return null
}

const toCurrency = (cents: number): string => {
  const dollars = cents / 100
  if (cents % 100 === 0) return '$' + dollars.toString()
  return '$' + dollars.toFixed(2)
}

export const patronDescriptionForUser = (user: UserState): string | null => {
  const cents = user.patreonEntitledAmountCents
  if (cents) {
    return `${toCurrency(cents)}: ${patronTitleForPledgeCents(cents)}`
  }

  return null
}
